<template>
    <b-sidebar
     id="portfolio-form-sidebar"
     :visible="isPortfolioFormSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData(portfolioData)"
     @hidden="resetForm"
     @change="(val) => $emit('update:is-portfolio-form-sidebar-active', val)"
   >

   <template #default="{ hide }"> 
       <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
           <h5 class="mb-0">
               Portfolio  Form
           </h5>
   
           <feather-icon
               class="ml-1 cursor-pointer"
               icon="XIcon"
               size="16"
               @click="hide"
           />
       </div>
       <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
         <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
            <b-tabs v-model="tabIndex">
                <b-tab title="Content">
                  
                  <validation-provider
                    #default="validationContext"
                    name="category_id"
                    rules="required"
                  >
                    <b-form-group
                      label="Category"
                      label-for="category_id"
                    >
              
                      <v-select
                              id="category_id"
                              v-model="Data.category_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="title"
                              :reduce="(option) => option.id"
                              :options="catsData"
                      />
              
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                      #default="validationContext"
                      name="Title"
                      rules="required"
                    >
                    <b-form-group
                      label="Title"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        v-model="Data.title"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Title"
                        @input="changeTitle"
                      />
        
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
            <validation-provider
              #default="validationContext"
              name="slug"
              rules="required"
            >
              <b-form-group
                label="Slug"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="Data.slug"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Slug"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="sub_title"
              rules="min:0"
            >
              <b-form-group
                label="Sub Title"
                label-for="sub_title"
              >
                <b-form-input
                  id="sub_title"
                  v-model="Data.sub_title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Sub Title"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="title_2"
              rules="min:0"
            >
              <b-form-group
                label="Title 2"
                label-for="title_2"
              >
                <b-form-input
                  id="title_2"
                  v-model="Data.title_2"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Title 2"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="short_description"
              rules="min:0"
            >
              <b-form-group
                  label="Short Description"
                  label-for="short_description"
              >
                  <b-form-textarea v-model="Data.short_description" />
                  <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="description"
              rules="min:0"
            >
              <b-form-group
                  label="Description"
                  label-for="description"
              >
                
                  <quill-editor :options="snowOption" v-model="Data.description" />
                  <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="IsActive"
              rules="min:0"
            >
              <b-form-group
                label="Is Active"
                label-for="IsActive"
              >
              
                <b-form-checkbox
                id="isactive"
                                checked="true"
                                class="custom-control-success"
                                v-model="Data.is_active"
                                switch
                  />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
                    
                </b-tab>
                <b-tab title="Image">
                    <div class="border rounded p-2 mb-2">
                      <h4 class="mb-1">
                        Banner Image
                      </h4>
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                            ref="refBannerEl"
                            :src="Data.bannerImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <small class="text-muted">Required image resolution 1940x540, image size 10mb.</small>
                          <b-card-text class="my-50">
                           
                          </b-card-text>
                          <div class="d-inline-block">
                            <b-form-file
                              ref="refInputBannerEl"
                              v-model="Data.bannerFile"
                              accept=".jpg, .png, .gif"
                              placeholder="Choose file"
                              @input="inputBannerRenderer"
                            />
                          </div>
                          <b-card-text class="my-50">
                            <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" v-if="Data.banner_image" @click="revomeBanner">Remove Image</b-button>
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div class="border rounded p-2 mb-2">
                      <h4 class="mb-1">
                        Logo Image
                      </h4>
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                            ref="refLogoEl"
                            :src="Data.logoImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <small class="text-muted">Required image resolution 192x192, image size 10mb.</small>
                          <b-card-text class="my-50">
                           
                          </b-card-text>
                          <div class="d-inline-block">
                            <b-form-file
                              ref="refInputLogoEl"
                              v-model="Data.logoFile"
                              accept=".jpg, .png, .gif"
                              placeholder="Choose file"
                              @input="inputLogoRenderer"
                            />
                          </div>
                          <b-card-text class="my-50">
                            <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" v-if="Data.logo_image" @click="revomeLogo">Remove Image</b-button>
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div class="border rounded p-2">
                      <h4 class="mb-1">
                        Featured Image
                      </h4>
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                            ref="refPreviewEl"
                            :src="Data.featuredImage"
                            height="110"
                            width="170"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <small class="text-muted">Required image resolution 312x312, image size 3mb.</small>
                          <b-card-text class="my-50">
                           
                          </b-card-text>
                          <div class="d-inline-block">
                            <b-form-file
                              ref="refInputEl"
                              v-model="Data.portfolioFile"
                              accept=".jpg, .png, .gif"
                              placeholder="Choose file"
                              @input="inputImageRenderer"
                            />
                          </div>
                          <b-card-text class="my-50">
                            <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" v-if="Data.cover_image" @click="revomeImage">Remove Image</b-button>
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </div>

                </b-tab>
                <b-tab title="gallery" v-if="portfolioData" >
                  
                    <upload-form  v-if="tabIndex == 2" :portfolio-data.sync="portfolioData" />
                </b-tab>
                <b-tab title="Repeater">
                  <form-repeater-basic v-if="Data.id" :blog-input-repeater.sync="BlogInputRepeater" :blog-input-repeater-remove.sync="BlogInputRepeaterRemove" />
                </b-tab>
                <b-tab title="SEO">
                  
                    <validation-provider
                        #default="validationContext"
                        name="meta_title"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Title"
                        label-for="meta_title"
                        >
                        <b-form-input
                            id="meta_title"
                            v-model="Data.meta_title"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Title"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> 
                    <validation-provider
                        #default="validationContext"
                        name="meta_description"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Description"
                        label-for="meta_description"
                        >
                        <b-form-input
                            id="meta_description"
                            v-model="Data.meta_description"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Description"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>   
                    <validation-provider
                        #default="validationContext"
                        name="meta_keywords"
                        rules="min:0"
                    >
                        <b-form-group
                        label="Meta Keywords"
                        label-for="meta_keywords"
                        >
                        <b-form-input
                            id="meta_keywords"
                            v-model="Data.meta_keywords"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Meta Keywords"
                        />
            
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>      
                </b-tab>
            </b-tabs>
           


           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Submit
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="hide"
             >
               Cancel
             </b-button>
           </div>
         </b-form>
         
         
       </validation-observer>
   </template>
   </b-sidebar>
</template>
<script>
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BListGroup,BListGroupItem
 } from 'bootstrap-vue'
 import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

 import { ValidationProvider, ValidationObserver } from 'vee-validate'
 import { ref } from '@vue/composition-api'
 import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import Ripple from 'vue-ripple-directive'
 import vSelect from 'vue-select'
 import countries from '@/@fake-db/data/other/countries'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
 import UploadForm from './UploadForm.vue'


import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { slugify } from '@/@fake-db/utils'
import FormRepeaterBasic from './FormRepeaterBasic.vue'
import { quillEditor } from 'vue-quill-editor'
export default {
   components: {
     BSidebar,
     BForm,
     BFormGroup,
     BFormInput,
     BFormTextarea,
     BFormCheckbox,
     BFormInvalidFeedback,
     BButton,
     BTabs, BTab,
     BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,
     vSelect,
     quillEditor,
     // Form Validation
     ValidationProvider,
     ValidationObserver,
     UploadForm,
     BListGroup,BListGroupItem,
     FormRepeaterBasic
   },
   directives: {
     Ripple,
   },
   model: {
     prop: 'isPortfolioFormSidebarActive',
     event: 'update:is-portfolio-form-sidebar-active',
   },
   props: {
     isPortfolioFormSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     portfolioData: {
       type: Object,
       required: false,
     },
   },
  
   data(){
      return {
        snowOption: {
          theme: 'snow',
        },
       
      }
   },
   setup(props, { emit }) {
       const toast = useToast()
       const catsData = ref([]);
      
       const userStorage = JSON.parse(localStorage.getItem('userData'));
       const  tabIndex = ref(0);
       const blankData = {
           featuredImage:require('@/assets/images/no-image.png'),
           bannerImage:require('@/assets/images/no-image.png'),
           logoImage:require('@/assets/images/no-image.png'),
           cover_image:'',
           banner_image:'',
           logo_image:'',
           created_by:userStorage.id,
           created_at:null,
           title: '',
           slug:'',
           sub_title:'',
           short_description:'',
           title_2:'',
           description:'',
           is_active:true,
           portfolioFile:null,
           bannerFile:null,
           logoFile:null,
           meta_title:'',
           meta_description:'',
           meta_keywords:'',
           ordering_count:0
       }
       const Data = ref(JSON.parse(JSON.stringify(blankData)))
       const repeaters = {
          id:null,
          layout_id: 1,
          image:null,
          title:null,
          description:null,
          show:false
       }
       const BlogInputRepeaterRemove = ref([]);
       const BlogInputRepeater = ref([JSON.parse(JSON.stringify(repeaters))]);
       const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
       }
       store.dispatch('app-portfolio/fetchCats', {})
      .then(response => {
        catsData.value = response.data.data
      })
       const getData = (data)=>{
        tabIndex.value =0;
        BlogInputRepeater.value = [repeaters];
        refPreviewEl.value.src = require('@/assets/images/no-image.png');
        refBannerEl.value.src = require('@/assets/images/no-image.png');
        refLogoEl.value.src = require('@/assets/images/no-image.png');
        
           if(data){
            if(data.content.length > 0){
                BlogInputRepeater.value = data.content;
              

              }
                data.featuredImage = data.cover_image?data.fullpath:Data.value.featuredImage;
                data.bannerImage = data.banner_image?data.fullbannerpath:Data.value.bannerImage;
                data.logoImage = data.logo_image?data.fulllogopath:Data.value.logoImage;
               data.is_active = data.is_active?true:false;
               Data.value  = JSON.parse(JSON.stringify(data));
           }
       }
       const onSubmit = () => {
        let formData = new FormData();
            Data.value.is_active = Data.value.is_active?1:0;
            Data.value.path = '/var/www/html/uploadfiles/uploads/music/portfolio/';
            Data.value.savepath = 'uploads/music/portfolio/';
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
             
            
            }
            if(BlogInputRepeater.value.length > 0){
              for(const [key, value] of Object.entries(BlogInputRepeater.value)) {
                //console.log('content['+key+']',value);
              
                formData.append(`content_id_`+key, value.id);
                formData.append(`content_layout_id_`+key, value.layout_id);
                formData.append(`content_title_`+key, value.title?value.title:'');
                formData.append(`content_description_`+key, value.description?value.description:'');
                formData.append(`content_image_`+key, value.image);
                formData.append(`content_contentFile_`+key, value.contentFile);
              
              
              }
            }
            if(BlogInputRepeaterRemove.value.length > 0){
              for(const [key, value] of Object.entries(BlogInputRepeaterRemove.value)) {
                //console.log('content['+key+']',value);
              
                formData.append(`content_remove_`+key, value);
              
              
              
              }
            }
            console.log( BlogInputRepeaterRemove.value.length,BlogInputRepeaterRemove.value);
            formData.append('count_conent_remove', BlogInputRepeaterRemove.value.length);
            formData.append('count_conent', BlogInputRepeater.value.length);
           if(Data.value.id){
             
               store.dispatch('app-portfolio/updatePortfolio', {formData:formData,data:Data.value})
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-portfolio-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }else{
               store.dispatch('app-portfolio/addPortfolio', {formData:formData})
               .then((res) => {
                   
                   if(res.data.success){
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Success`,
                       icon: 'BellIcon',
                       variant: 'success',
                       text: `${res.data.message}`,
                       },
                   });
                   emit('refetch-data')
                   emit('update:is-portfolio-form-sidebar-active', false)
                   }else{
                   
                   toast({
                       component: ToastificationContent,
                       position: 'top-right',
                       props: {
                       title: `Error`,
                       icon: 'BellIcon',
                       variant: 'danger',
                       text: `${res.data.message}`,
                       },
                   });
               
                   
                   }
               
               })
           }

       }

       
        const {
       refFormObserver,
       getValidationState,
       resetForm,
     } = formValidation(resetData)
     //upload
     const refInputEl = ref(null)
      const refPreviewEl = ref(null)

      
     const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { 
        //console.log('refInputEl',refInputEl);
        refPreviewEl.value.src = base64 
     })

      const refInputBannerEl = ref(null);
     const refBannerEl = ref(null)
     
     const { inputBannerRenderer } = useInputImageRenderer(refInputBannerEl, base64 => { 
     
        refBannerEl.value.src = base64 
    })
    const refInputLogoEl = ref(null);
     const refLogoEl = ref(null)
    const { inputLogoRenderer } = useInputImageRenderer(refInputLogoEl, base64 => { 
    
      refLogoEl.value.src = base64 
    })


    
     const revomeImage = ()=>{
      Data.value.featuredImage = require('@/assets/images/no-image.png');
      Data.value.cover_image = '';
     }
     const revomeBanner = ()=>{
      Data.value.bannerImage = require('@/assets/images/no-image.png');
      Data.value.banner_image = '';
     }

     const revomeLogo = ()=>{
      Data.value.logoImage = require('@/assets/images/no-image.png');
      Data.value.logo_image = '';
     }
     const changeTitle  = () => {
        Data.value.slug =  slugify(Data.value.title )
       }
     return {
       Data,
       onSubmit,
       catsData,
       refFormObserver,
       getValidationState,
       resetForm,
       getData,

       refInputEl,   
       refPreviewEl,
       inputImageRenderer,
       revomeImage,

       refInputBannerEl,
       refBannerEl,
       inputBannerRenderer,
       revomeBanner,

       refInputLogoEl,
       refLogoEl,
       inputLogoRenderer,
       revomeLogo,
       

       tabIndex,
       changeTitle,
       BlogInputRepeaterRemove,
        BlogInputRepeater
       
     }
  
   }
   
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>